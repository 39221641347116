import React, { Component } from "react";
import styled from "styled-components"
import PropTypes from "prop-types";
// import { motion } from "framer-motion";
import { Link } from "gatsby";
// import Img from "gatsby-image";
import Text, { Section, Grid } from "../components/Theme";
import Layout from "../components/layout";
import Header from "../components/Header";
import algoliasearch from 'algoliasearch/lite';
// import { orderBy } from 'lodash';
import media from "styled-media-query";
import {
    InstantSearch,
    Hits,
    SearchBox,
    Pagination,
    // Highlight,
    // RefinementList,
    connectRefinementList,
    Configure,
} from 'react-instantsearch-dom';
import qs from "qs";
import IntroSectionBackground from "../images/background__daily.jpg";
import IconSearch from "../images/icons/icon__search.svg";
import withLocation from "../components/Router/withLocation.js"; 
import Footer from "../components/footer.js";

const IntroSection = styled.div`
    background-image: url(${IntroSectionBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    color: var(--white);

    Section {
        min-height: calc(60vh - 100px);
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-flow: column-reverse wrap;
        align-items: baseline;
        padding-bottom: 10vh;
    }

    .intro {
        &__category {
            text-transform: uppercase;
            border: 1px solid var(--white);
            padding: 4px 10px;
            margin-bottom: 20px;
            display: inline-block;
        }
    }
`;

const SearchHeader = styled.div`
    ${Grid};
    margin-bottom: 40px;
    grid-row-gap: 10px;

    #filter_toggle {
        visibility: hidden;
        position: absolute;
    }

    h2 {
        margin: 60px 0 60px 0;
    }

    ul.ais-RefinementList {
        margin: 0;
        padding: 0;
        grid-column: 3/9;
        grid-row: 2/3;
        align-self: start;
        display: flex;
        justify-content: space-between;

        ${media.lessThan("large")`
            grid-column: 1/13;
            grid-row: 3/4;
            flex-wrap: wrap;
            justify-content: start;
        `}

        ${media.lessThan("medium")`
            grid-column: 1/13;
            grid-row: 3/4;
        `}

        li {
            font-family: "am";
            text-transform: uppercase !important;
            font-weight: 100 ;
            margin: 0;
            padding: 0;
            text-align: left;

            label {
                cursor: pointer;

                &:hover {
                    opacity: 1;
                }
            }

            span {
                font-weight: 100;
            }

            label {
                margin: 0 30px 0 0;
                padding: 0;
            }

            input[type=checkbox]{
                display: none;
            }

            input[type=checkbox]{
                display: none;
            }
        }
    }

    li {
        list-style: none;
        display: inline-block;

        label {

            input {
                &:checked + span, &:hover + span {
                    opacity: 1;
                    border-bottom: 1px solid rgba(0,0,0,.5);
                }
            }

            span {
                opacity: .4;
                display: inline-block;
            }
        }

        &.ais-RefinementList-item--selected {
            opacity: 1;
            text-decoration: underline;
        }

        &:hover {
            opacity: 1;
        }
    }

    .ais-RefinementList-count {
        display: none;
    }

    .ais-SearchBox {
        width: 100%;
        border: 0px;
        background-color: #f1f1f1;
        background-repeat: no-repeat;
        background-position: 1.5rem center;
        height: 63px;
        font-size: 20px;
        margin-top: 20px;
        grid-column: 3/9;
        grid-row: 3/4;
        align-self: start;
        text-transform: uppercase;
        background-image: url(${IconSearch});

        ${media.lessThan("large")`
            grid-column: 1/13;
            grid-row: 4/5;
        `}

        input {
            border: 0;
            font-family: "am";
            padding: 1.2rem 2rem 1.2rem 3.5rem;
            background: none;
            width: 100%;
            text-transform: uppercase !important;
        }
    }
`;

const FilterToggle = styled.label`
  grid-row: 2/3;
  grid-column: 1/3;
  margin-bottom: 10px;

  ${media.lessThan("large")`
      grid-column: 1/2;
  `}
`;

const IndexHeader = styled(Text)`
   ${media.lessThan("large")`
       grid-column: 1/12;
   `}
`;

const SearchLabel = styled.div`
${media.lessThan("large")`
    margin-bottom: 10px;
    margin-top: 40px;
    grid-column: 1/2;
  `}
`;

const ArchiveList = styled.ol`
    grid-column: 1/13;
    padding: 0;

    li {
        list-style: none;
    }

    &:hover li {
        opacity: 1;

        &:hover {
            opacity: 1;
        }
    }
`;

const ArchiveListItem = styled.div `
    list-style: none;
    margin: 0;
    padding: 0;
    padding: 15px 0px;
    border-bottom: 1px solid #000;
    font-family: am, sans-serif;
    line-height: 24px;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        width: 100%;
    }

    &:hover  {
        img  {
            pointer-events: none;
            z-index: 999;
        }
    }

    .accordion-list {
        list-style: none;
        margin: 0;
        padding: 0;
        background-color: #fff;
        max-width: 30rem;
        border-radius: 0.4rem;
        overflow: hidden;
        box-shadow: 0 0 0.8rem 0.1rem rgba(15, 72, 179, 0.06), 0 20px 30px -10px rgba(15, 72, 179, 0.2);
    }

    .accordion-list__item + .accordion-list__item {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .accordion-item--opened .accordion-item__icon {
        transform: rotate(180deg);
    }

    .accordion-item--opened .accordion-item__inner {
        max-height: 100rem;
        transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
        transition-duration: 0.5s;
        transition-property: max-height;
    }

    .accordion-item--opened .accordion-item__content {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0.2s;
        transition-timing-function: ease-in-out;
        transition-duration: 0.2s;
        transition-property: opacity, transform;
    }

    .accordion-item__line {
        display: block;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        z-index: 2;
        position: relative;
    }

    .accordion-item__title {
        font-family: var(--sans-serif);
        text-transform: none;
        font-size: 1.6rem;
        margin: 0;
        color: #121212;
    }

    .accordion-item__icon {
        width: 1.2rem;
        height: 1.2rem;
        transition: transform 0.3s ease-in-out;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);
        opacity: 0.6;
    }

    .accordion-item__inner {
        max-height: 0;
        overflow: hidden;
        text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
        transition-duration: 0.5s;
        transition-delay: 0.2s;
        transition-property: max-height;
        z-index: 1;
        position: relative;
    }

    .accordion-item__content {        opacity: 0;
        transform: translateY(-1rem);
        transition-timing-function: linear, ease;
        transition-duration: 0.1s;
        transition-property: opacity, transform;
        transition-delay: 0.5s;
        padding: 0;
    }

    .accordion-item__paragraph {
        margin: 0 0 30px;
        text-transform: none;
        font-family: var(--sans-serif);
        font-size: 1rem;
        color: #333;
        font-weight: 300;
        line-height: 1.3;

        ${media.lessThan("medium")`
            margin: 0 0 20px;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
        `}
    }

    .accordion-source {
        font-size: 12px;
        opacity: 0.7;
        letter-spacing: 1px;
    }

    .accordion-image,
    .accordion-content-left {
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
    }

    .results-geo {
        width: auto;
        text-align: left;
        margin-bottom: 20px;
        opacity: 0.7;
    }

    .align-center {
        align-items: center;

        ${media.lessThan("medium")`
            align-items: flex-start;
        `}
    }

    .share-container {
        margin-top: 20px;
        margin-bottom: 20px;
    }
`

const StaticRefinementList = connectRefinementList(
    ({ values, currentRefinement, items, refine }) => (
      <ul className="ais-RefinementList">
        {values.map(staticItem => {
          const { isRefined } = items.find(
            item => item.label === staticItem.label
          ) || {
            isRefined: false,
          };

          return (
            <li key={staticItem.value}>
              <label className="ais-RefinementList-label">
                <input
                  type="checkbox"
                  className="ais-RefinementList-checkbox"
                  value={staticItem.value}
                  checked={isRefined}
                  onChange={event => {
                    const value = event.currentTarget.value;
                    const next = currentRefinement.includes(value)
                      ? currentRefinement.filter(current => current !== value)
                      : currentRefinement.concat(value);

                    refine(next);
                  }}
                />
                <span>{staticItem.label}</span>
              </label>
            </li>
          );
        })}
      </ul>
    )
  );

const searchClient = algoliasearch(
    'AI7O5IJ8D5',
    '7f1a509e834f885835edcfd3482b990c'
);

const DEBOUNCE_TIME = 400;
const createURL = state => `?${qs.stringify(state)}`;

const urlToSearchState = location => qs.parse(location.search.slice(1));

class ArchivePage extends Component {

     /* eslint-disable react/destructuring-assignment */
     state = {
        searchState: urlToSearchState(this.props.location),
        lastLocation: this.props.location,
        // allProducts: this.props.data.allDatoCmsProduct.edges
    }
    /* eslint-enable react/destructuring-assignment */

    static getDerivedStateFromProps(props, state) {
        if (props.location !== state.lastLocation) {
            return {
                searchState: urlToSearchState(props.location),
                lastLocation: props.location,
                // allProducts: props.data.allDatoCmsProduct.edges
            };
        }

        return null;
    }

    onSearchStateChange = (searchState) => {
        clearTimeout(this.debouncedSetState);

        this.debouncedSetState = setTimeout(() => {
            const { navigate } = this.props;
            navigate(createURL(searchState));

            // navigate.push(
            //     searchStateToUrl(this.props, searchState),
            //     searchState,
            // );
        }, DEBOUNCE_TIME);

        this.setState({ searchState });
    };

    render() {
        const { state, onSearchStateChange } = this;

        return (
            <Layout parent="archive-page">
                <Header content_color="black" />
                
                {/*<IntroSection>
                    <Section>
                        <div className="intro__content">
                            <Text ultra>
                                Index
                            </Text>
                            <Text body>
                                Perspectives on our planet and how it's being impacted
                                <br />
                                <br />
                            </Text>
                        </div>
                    </Section>
                </IntroSection>*/}
                                <InstantSearch 
                indexName="overview" 
                searchClient={searchClient} 
                searchState={state.searchState}
                onSearchStateChange={onSearchStateChange}
                createURL={createURL}
                className="archive-list">
                        <SearchHeader>
                            <IndexHeader sub col="1/13" mobileCol="1/13">Overview Index</IndexHeader>
                            <input type="checkbox" id="filter_toggle" />
                            <FilterToggle for="filter_toggle" className="caption">Filter:</FilterToggle>
                            
                            <StaticRefinementList
                            attribute="_tags.name"
                            values={[
                                { label: 'Food', value: 'Food' },
                                { label: 'Mining', value: 'Mining' },
                                { label: 'Energy', value: 'Energy' },
                                { label: 'Cities', value: 'Cities' },
                                { label: 'Design', value: 'Design' },
                                { label: 'Transport', value: 'Transport' },
                                { label: 'Leisure', value: 'Leisure' },
                                { label: 'Waste', value: 'Waste' },
                                { label: 'Nature', value: 'Nature' },
                            ]}
                            />
                            {/* <RefinementList attribute="_tags.name" transformItems={items => orderBy(items, "label", "asc")} /> */}
                            {/*<SearchLabel className="caption archive-search-label">Search:</SearchLabel>*/}
                            <SearchBox />
                        </SearchHeader>

                        <Configure hitsPerPage={30} />
              
                    
                    <div className="w-layout-grid main-grid overview-index">
                        
                        <ArchiveList id="w-node-e32af32b47af-c6b3f613" className="grid-x">
                            <div className="cell small-12">
                                <Hits hitComponent={Hit} />
                            </div>
                        </ArchiveList>
                  
                        <Pagination />
                    </div>
                    
                    </InstantSearch>    
                    <Footer />
            </Layout>  
        )
    }
}

export default withLocation(ArchivePage);

function Hit(props) {
    return (
        <ArchiveListItem>
            <AccordionItem {...props} />
        </ArchiveListItem>
    );
}

class AccordionItem extends React.Component {
  state = {
    opened: false
  }

  render () {
    const {
      props: {
        hit: {
            title,
            objectID,
            description,
            source,
            image,
            location,
            // shopLink,
            slug,
            _geoloc
        }
      },
      state: {
        opened
      }
    } = this

    return (
        <div
            key={objectID}
            {...{
                className: `accordion-item, ${opened && 'accordion-item--opened'}`,
                onClick: (e) => {
                    if (e.target.parentElement.className !== 'accordion-item__content' &&
                        e.target.nodeName.toLowerCase() !== 'a' &&
                        e.target.nodeName.toLowerCase() !== 'span'
                    ) {
                        this.setState({ opened: !opened })
                    }
                }
            }}
        >
            <div className="grid-x align-center">
                <div

                    className={`${(opened) ? 'accordion-content-left cell small-7 medium-7 large-5' : 'accordion-content-left cell small-9 medium-8 large-5'}`}
                >
                    <div {...{ className: 'accordion-item__line' }}>
                        <h3 {...{ className: 'accordion-item__title' }}>
                            {title}
                        </h3>
                        {/*<span {...{ className: 'accordion-item__icon' }}/>*/}
                    </div>
                    <div {...{ className: 'accordion-item__inner' }}>
                        <div {...{ className: 'accordion-item__content' }}>
                            <div className="results-geo">
                                {_geoloc.lat.toFixed(6)}°,{" "}
                                {_geoloc.lng.toFixed(6)}° - {location}
                            </div>
                            <p {...{ className: 'accordion-item__paragraph' }}>
                                {description}
                            </p>
                            {/* TODO: MAKE THIS SHOPLINK CHECK WORK */}
                            {/*{slug && (
                                <>
                                    <Link to={`/overviews/${slug}`}>
                                        <button className="section-button">
                                            <span>Shop Prints</span>
                                            <img
                                                src={require("../images/icons/icon__chevron--white--right.svg")}
                                                alt="daily overview"
                                                className="image-icon"
                                            />
                                        </button>
                                    </Link>
                                    <p className="share-container">
                                        <span
                                            href="#"
                                            className="copy__share"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            onClick={() => {
                                                navigator.clipboard.writeText("https://www.over-view.com/overviews/" + slug);
                                            }}
                                        >
                                            <img
                                                src={require("../images/icons/icon__link--grey.svg")}
                                                alt="daily overview: email"
                                                className="image-icon__medium"
                                            />
                                        </span>
                                        <a
                                            href={`mailto:?subject=Check out this post on Overview&body=Check out the ${title} post on Overview: https://www.over-view.com/overviews/${slug}`}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            <img
                                                src={require("../images/icons/icon__mail--grey.svg")}
                                                alt="daily overview: email"
                                                className="image-icon__medium"
                                            />
                                        </a>
                                        <a
                                            href={
                                                "https://www.facebook.com/sharer/sharer.php?u=https://www.over-view.com/overviews/" + slug
                                            }
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            <img
                                                src={require("../images/icons/icon__facebook--grey.svg")}
                                                alt="daily overview: email"
                                                className="image-icon__medium"
                                            />
                                        </a>
                                        <a
                                            href={
                                                "http://twitter.com/share?text=" + title + "&url=https://www.over-view.com/overviews/" + slug
                                            }
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            <img
                                                src={require("../images/icons/icon__twitter--grey.svg")}
                                                alt="daily overview: email"
                                                className="image-icon__medium"
                                            />
                                        </a>
                                    </p>
                                </>
                            )}*/}
                            <span className="accordion-source">{source}</span>
                        </div>
                    </div>
                </div>
                <div
                    className={`${(opened) ? 'accordion-image cell small-offset-1 auto' : 'accordion-image cell small-offset-1 medium-offset-3 large-offset-6 auto'}`}
                >
                    <img
                        src={`${image.url}?auto=compress%2Cformat&fit=crop&w=500&h=500&dpr=2`}
                        align="left"
                        alt={title}
                    />
                </div>
            </div>
        </div>
    )
  }
}

Hit.propTypes = {
    hit: PropTypes.object.isRequired,
    // allProducts: PropTypes.object.isRequired,
};

// export const query = graphql`
//     query MyQuery {
//       allDatoCmsProduct {
//         edges {
//           node {
//             id
//             title
//             slug
//           }
//         }
//       }
//     }

// `;
